@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.eventSelectionContainer {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
