@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.baseContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 160px;
  position: relative;

  @media (max-width: $mobile-max-size) {
    flex-direction: column;
    padding: 20px;
  }
}

.baseContainerOverlay {
  position: absolute;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: $white-smoke;
}

.column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 40px 80px;
  position: relative;

  &.textColumn {
    background-color: $white-smoke;
    border-radius: 10px;
    opacity: 0.9;
    border: 4px solid $prussian-blue;

    p {
      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $mobile-max-size) {
      padding: 20px;
    }
  }

  @media (max-width: $mobile-max-size) {
    width: 100%;
    // padding: 10px 0px;
  }
}

.description {
  color: $prussian-blue;
  line-height: 25px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-align: center;
  overflow-y: scroll;

  @media (max-width: $mobile-max-size) {
    line-height: 16px;
    font-size: 11px;
    margin-bottom: 0;
  }
}
