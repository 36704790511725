@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.headerContainer {
  position: relative;

  &.mobile {
    .imageCollage {
      height: 330px;
      padding: 20px;

      &:first-child {
        padding-bottom: 0;
      }
    }
    .centerPadding {
      height: 10%;
    }
    .headerLogo {
      height: 80px;
    }
    .overlay {
      height: 100px;
    }
  }
}

.imageCollage {
  display: flex;
  flex-direction: row;
  height: 450px;
  padding: 20px;
}

.centerContainer {
  flex: 1 1 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 5px solid $white;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.centerPadding {
  height: 30%;
}

.mainImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 1 auto;
  border-radius: 5px;
}

.supportImage {
  flex: 1 1 auto;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25%;
  border: 5px solid $white;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.overlay {
  display: flex;
  flex-direction: row;
  height: 350px;
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.headerLogo {
  height: 200px;
}
