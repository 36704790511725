@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.mobileHeader {
  height: 48px;
  box-shadow: 0 1px 2px 0 gray;
  background-color: $caput-mortuum;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  .headerMenuPopover {
    margin-left: 10px;
  }
}

.headerLogo {
  margin: 0 14px;
  display: flex;
  align-items: center;
  margin-left: 26px;

  .headerLogoImage {
    height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}

.header {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.navItem {
  color: $caput-mortuum;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: $caput-mortuum;
    font-weight: 500;
    text-decoration: none;
  }
}

.mobileHeaderNavBarMenu {
  min-width: 100px;
}

.menuButton.menuButton.menuButton.menuButton {
  :global(.bp5-icon) {
    color: $white-smoke;
  }
}

.socialMediaSection {
  display: flex;
  flex-direction: row;
}

.headerLogoSocial {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.headerLogoSocialImage {
  height: 16px;

  &:hover {
    cursor: pointer;
    color: white;
    font-weight: 500;
  }
}
