@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.subscriptionPreviewContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
  color: $white-smoke;
}

.textSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: $mobile-max-size) {
    margin-bottom: 15px;
    font-size: 11px;
  }
}

.label {
  font-weight: 600;
}
