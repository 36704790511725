@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.navigationBar {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  background-color: $caput-mortuum;
  padding: 5px 10px;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

.headerLogo {
  flex: 0 0 auto;
}

.headerLogoImage {
  height: 50px;
  fill: $white-smoke;
  stroke: $white-smoke;
}

.navContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navItem {
  color: $white-smoke;
  padding: 10px 20px;
  margin: 0px 20px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: white;
    font-weight: 500;
    text-decoration: none;
  }
}

.headerLogoSocial {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}

.headerLogoSocialImage {
  height: 22px;

  &:hover {
    cursor: pointer;
    color: white;
    font-weight: 500;
  }
}
