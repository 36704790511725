/* Color Theme Swatches in Hex */
$white-smoke: #f3f1f2;
$coyote: #73704c;
$ebony: #bf9788;
$prussian-blue: #734e39;
$caput-mortuum: #592c28;

// $Middle-aged-caucasian-couple-living-on-a-farm-in-the-countryside-smiling-portrait-1-hex: #73704c;
// $Middle-aged-caucasian-couple-living-on-a-farm-in-the-countryside-smiling-portrait-2-hex: #f2f1df;
// $Middle-aged-caucasian-couple-living-on-a-farm-in-the-countryside-smiling-portrait-3-hex: #734e39;
// $Middle-aged-caucasian-couple-living-on-a-farm-in-the-countryside-smiling-portrait-4-hex: #bf9788;
// $Middle-aged-caucasian-couple-living-on-a-farm-in-the-countryside-smiling-portrait-5-hex: #592c28;

$mobile-max-size: 767px;
