@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.previewList {
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px 20px;

  @media (max-width: $mobile-max-size) {
    padding: 0 10px 10px 10px;
  }
}
