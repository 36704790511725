@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white-smoke;
  overflow-y: auto;

  @media (max-width: $mobile-max-size) {
    // padding-bottom: 50px;
  }
}

.scrollContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.scrollContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.pageFooter {
  display: flex;
  flex: 1 1 auto;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $caput-mortuum;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  opacity: 0.4;

  @media (max-width: $mobile-max-size) {
    padding: 30px;
  }

  .footerLogo {
    height: 40px;

    @media (max-width: $mobile-max-size) {
      height: 20px;
    }
  }

  .footerText {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 12px;

    @media (max-width: $mobile-max-size) {
      font-size: 8px;
    }
  }

  .footerSubtext {
    font-weight: 600;
    font-size: 10px;

    @media (max-width: $mobile-max-size) {
      font-size: 8px;
    }
  }
}
