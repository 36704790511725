@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.contentContainer {
  background-color: $coyote;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 200px;
  text-align: center;

  @media (max-width: $mobile-max-size) {
    padding: 20px 36px;
  }
}

.title {
  color: $white-smoke;
  text-transform: uppercase;
  border-bottom: 1px solid $white-smoke;
  padding: 5px 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;

  @media (max-width: $mobile-max-size) {
    font-size: 11px;
    margin-bottom: 15px;
  }
}

.description {
  color: $white-smoke;
  line-height: 25px;
  margin-bottom: 10px;
  letter-spacing: 1px;

  @media (max-width: $mobile-max-size) {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 10px;
  }
}

.decorationLogo {
  height: 40px;
  opacity: 0.5;

  @media (max-width: $mobile-max-size) {
    height: 20px;
  }
}
