@import "../node_modules/@blueprintjs/core/lib/css/blueprint.css";
/* @import "../node_modules/@blueprintjs/core/lib/scss/variables.scss"; */

body {
  margin: 0 !important;
  padding: 0;
  height: calc(100vh);
  overflow: hidden;
  font-family: sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

:global(body) {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 !important;
}

:global(html) {
  width: 100%;
  height: 100%;
}

:focus {
  outline:none;
}