@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.flowerGrid {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: center;

  @media (max-width: $mobile-max-size) {
    padding: 30px 5px 5px 5px;
  }
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 0 50px;
  justify-content: flex-end;
  align-items: center;
  font-family: "Raleway", sans-serif;
  position: absolute;
  top: 10px;
  right: 0;

  @media (max-width: $mobile-max-size) {
    top: 25px;
    justify-content: center;
    margin: 0;
    right: unset;
    width: 100%;
  }

  .title {
    font-weight: 600;
    margin-right: 15px;
    font-size: 12px;

    @media (max-width: $mobile-max-size) {
      font-size: 10px;
    }
  }

  .filter {
    font-family: "Raleway", sans-serif;
    margin-bottom: 0;
    font-size: 12px;
    color: $coyote;

    &:not(:last-child) {
      margin-right: 15px;
    }

    @media (max-width: $mobile-max-size) {
      font-size: 10px;
    }
  }
}

.header {
  flex: 1 1 auto;
  margin: 0 50px;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  color: $coyote;

  @media (max-width: $mobile-max-size) {
    font-size: 12px;
  }
}

:global(.bp5-control-indicator)::before {
  color: $coyote;
  background-color: $coyote;
}
