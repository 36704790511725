@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.flowerCard {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 300px;
  padding: 0px;
  margin: 15px;

  @media (max-width: $mobile-max-size) {
    width: 115px;
    height: 150px;
    margin: 5px;
  }
}

.imageContainer {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px 3px 0 0;
}

.title {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $white;
  border-radius: 5px;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: $prussian-blue;

  @media (max-width: $mobile-max-size) {
    font-size: 10px;
    padding: 5px;
  }
}
