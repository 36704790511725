@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.baseContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;

  @media (max-width: $mobile-max-size) {
    flex-direction: column;
    padding: 20px;
  }
}

.column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 40px;

  &.textColumn {
    background-color: $prussian-blue;
    border-radius: 10px;
    max-height: 500px;

    @media (max-width: $mobile-max-size) {
      padding: 15px 25px;
    }
  }
  @media (max-width: $mobile-max-size) {
    width: 100%;
    padding: 0;

    &:not(:first-child) {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.title {
  color: $white-smoke;
  text-transform: uppercase;
  border-bottom: 1px solid $white-smoke;
  padding: 5px 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;

  @media (max-width: $mobile-max-size) {
    font-size: 11px;
    margin-bottom: 15px;
  }
}

.description {
  color: $white-smoke;
  line-height: 25px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-align: center;
  overflow-y: scroll;

  @media (max-width: $mobile-max-size) {
    line-height: 16px;
    font-size: 11px;
    margin-bottom: 0;
  }
}

.decorationDivider {
  border-bottom: 1px solid $white-smoke;
  padding: 0 20px;
  margin: 10px 0;
  opacity: 0.5;

  @media (max-width: $mobile-max-size) {
    margin: 10px 0;
  }
}

.decorationLogo {
  height: 40px;
  opacity: 0.5;

  @media (max-width: $mobile-max-size) {
    height: 24px;
    margin-top: 10px;
  }
}

.imageContainer {
  height: 450px;
  width: 100%;
  border: 8px solid white;
  border-radius: 12px;
  flex: 1 1 auto;
  display: flex;

  @media (max-width: $mobile-max-size) {
    height: 250px;
    border-radius: 8px;
  }

  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
  }
}
