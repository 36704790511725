@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.baseContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;

  @media (max-width: $mobile-max-size) {
    flex-direction: column;
    padding: 10px 20px;
  }
}

.column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

  &:not(:last-child) {
    margin-right: 40px;

    @media (max-width: $mobile-max-size) {
      margin-right: 0px;
    }
  }

  &.textColumn {
    background-color: $prussian-blue;
    border-radius: 10px;
    width: 40%;
    padding: 40px;

    @media (max-width: $mobile-max-size) {
      padding: 15px 30px;
      // margin-bottom: 15px;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &.portraitImages {
    width: 25%;

    .imageContainer {
      width: 400px;

      @media (max-width: $mobile-max-size) {
        width: 90%;
        height: 350px;
      }
    }

    @media (max-width: $mobile-max-size) {
      width: 100%;
    }
  }

  @media (max-width: $mobile-max-size) {
    width: 100%;

    &:not(.portraitImages) {
      .imageContainer {
        height: 250px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.title {
  color: $white-smoke;
  text-transform: uppercase;
  border-bottom: 1px solid $white-smoke;
  padding: 5px 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;

  @media (max-width: $mobile-max-size) {
    font-size: 11px;
    margin-bottom: 15px;
  }
}

.description {
  color: $white-smoke;
  line-height: 25px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: $mobile-max-size) {
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 0;
  }
}

.decorationDivider {
  border-bottom: 1px solid $white-smoke;
  padding: 0 20px;
  margin: 10px 0;
  opacity: 0.5;

  @media (max-width: $mobile-max-size) {
    margin: 10px 0;
  }
}

.decorationLogo {
  height: 40px;
  opacity: 0.5;

  @media (max-width: $mobile-max-size) {
    height: 24px;
  }
}

.imageContainer {
  height: 550px;
  width: 100%;
  border: 8px solid white;
  border-radius: 12px;

  //   @media (max-width: $mobile-max-size) {
  //     height: 250px;
  //     border-radius: 8px;
  //   }

  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  }
}

.swiperComponent {
  height: 100%;
}

:global(.swiper-pagination-bullet) {
  background: white !important;
  opacity: 1 !important;
}

:global(.swiper-pagination-bullet-active) {
  background: $ebony !important;
}
