@import "@blueprintjs/core/lib/scss/variables.scss";
@import "./variables.scss";

.eventPreviewContainer {
  // border: 4px solid white;
  //   width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  // width: 200px;
  min-width: 150px;
  max-width: 16%;
  height: 220px;
  opacity: 0.6;
  margin: 10px;
  border-radius: 5px;
  padding: 0 !important;

  @media (max-width: $mobile-max-size) {
    height: 170px;
    min-width: 110px;
    margin: 6px;
    // max-width: 16%;
  }

  &:hover {
    cursor: pointer;
    // border-color: $prussian-blue;
    border-color: $caput-mortuum;
    .title {
      // background-color: $prussian-blue;
      background-color: $caput-mortuum;

      color: $white;
    }
  }

  &.selected {
    opacity: 1;
    // border: 1px solid $prussian-blue;
    border: 1px solid $caput-mortuum;

    .title {
      // background-color: $prussian-blue;
      background-color: $caput-mortuum;
      color: $white;
    }
  }
}

.imageContainer {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $white;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  // color: $prussian-blue;
  color: $caput-mortuum;
}
